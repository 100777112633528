import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import BulletList from "../../../../components/bullet-list"
import Bullet from "../../../../components/bullet"
import { getCourseNavigation } from "../../../../store/courses"
import Heading from "../../../../components/heading"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum nächsten Kapitel"
          to="/kurse/jahreszeiten/02-eigenes-gedicht"
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span">Hölderlins Jahreszeitengedichte</Heading>
            <Heading as="h1" level={2}>
              Eine kurze Zusammenfassung
            </Heading>
          </Stack>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Im Kurs haben wir uns die Strophen, Verse, Silben und Reime des
              Gedichts angesehen und dabei bemerkt, dass das Gedicht sehr
              regelmäßig aufgebaut ist.
            </Bullet>
            <Bullet>
              Bei der Bestimmung der Nomen, Adjektive und Verben fiel auf, dass
              Hölderlin mit den Adjektiven sehr sparsam war, aber viele Nomen
              verwendet hat, die auch in seinen anderen Gedichten vorkommen.
            </Bullet>
            <Bullet>
              Beim Erforschen von Hölderlins Handschrift haben wir mehr über die
              Entstehung des Gedichts und Hölderlins Pseudonym "Scardanelli"
              erfahren.
            </Bullet>
            <Bullet>
              Zuletzt hast du dir angehört, wie das Gedicht klingt, wenn man es
              laut vorträgt, und es anschließend selbst zum Klingen gebracht.
            </Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
